import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StatusCodes } from 'http-status-codes';
import {
  SiteNotFoundError,
  UnableToUpdateSiteAreaError,
  WrongPhasesError,
} from '@repository/site-area/errors/update-site-area.error';
import { Utils } from '@utils/Utils';
import { SiteAreaApiService } from '../../api/site-area/site-area.api.service';
import { SiteArea, SiteAreaSmartChargingSettings, SubSiteAreaAction } from '../../types/SiteArea';
import { ActionResponse, SiteAreaDataResult } from '../../types/DataResult';
import { RestResponse } from '../../types/GlobalType';
import { HTTPError } from '../../types/HTTPError';

@Injectable()
export class SiteAreaRepositoryService {
  public constructor(private readonly siteAreaApi: SiteAreaApiService) {}

  public getSiteArea(
    siteAreaId: string,
    options: { withSite?: boolean; withParentSiteArea?: boolean } = {},
  ): Observable<SiteArea> {
    return this.siteAreaApi
      .getSiteArea(siteAreaId, options)
      .pipe(map((siteArea) => this.convertSmartChargingParametersInWatt(siteArea)));
  }

  public getSiteAreas(
    limit = 50,
    skip = 0,
    params: { Issuer?: boolean; ParentSiteAreaId?: string; Search?: string; SortFields?: string } = {},
  ): Observable<SiteAreaDataResult> {
    return this.siteAreaApi.getSiteAreas(limit, skip, params).pipe(
      map((result) => {
        result.result = result.result.map((siteArea) => this.convertSmartChargingParametersInWatt(siteArea));

        return result;
      }),
    );
  }

  public updateSiteArea(siteArea: SiteArea, subSiteAreaActions: SubSiteAreaAction[] = []): Observable<ActionResponse> {
    const updatedSiteArea = this.updateSiteAreaSmartChargingInformation(siteArea);

    return this.siteAreaApi.updateSiteArea(updatedSiteArea, subSiteAreaActions).pipe(
      map((response) => {
        if (response.status !== RestResponse.SUCCESS) {
          throw new Error('Site Area could not be updated');
        }

        return response;
      }),
      catchError((error) => {
        switch (error.status) {
          case HTTPError.THREE_PHASE_CHARGER_ON_SINGLE_PHASE_SITE_AREA:
            throw new WrongPhasesError();
          case StatusCodes.NOT_FOUND:
            throw new SiteNotFoundError();
          default:
            throw new UnableToUpdateSiteAreaError();
        }
      }),
    );
  }

  public createSiteArea(siteArea: SiteArea, subSiteAreaActions: SubSiteAreaAction[] = []): Observable<ActionResponse> {
    const updatedSiteArea = this.updateSiteAreaSmartChargingInformation(siteArea);

    return this.siteAreaApi.createSiteArea(updatedSiteArea, subSiteAreaActions).pipe(
      map((response) => {
        if (response.status !== RestResponse.SUCCESS) {
          throw new Error('Site Area could not be created');
        }

        return response;
      }),
    );
  }

  public updateSmartChargingSettings(siteArea: SiteAreaSmartChargingSettings): Observable<void> {
    const { id, smartChargingParameters, name, siteID } = this.updateSiteAreaSmartChargingInformation(siteArea);
    return this.siteAreaApi.updateSmartChargingSettings({ id, smartChargingParameters, name, siteID });
  }

  private convertSmartChargingParametersInWatt(siteArea: SiteArea): SiteArea {
    const updatedSiteArea = Utils.cloneObject(siteArea);

    if (updatedSiteArea.smartChargingParameters?.defaultCurrent) {
      updatedSiteArea.smartChargingParameters!.defaultCurrent = this.ampsToWatt(
        updatedSiteArea.smartChargingParameters?.defaultCurrent,
        updatedSiteArea.voltage,
        updatedSiteArea.numberOfPhases,
      );
    }

    // Transform Amps to Watt
    if (updatedSiteArea.smartChargingParameters?.minimalCurrent) {
      updatedSiteArea.smartChargingParameters.minimalCurrent = this.ampsToWatt(
        updatedSiteArea.smartChargingParameters?.minimalCurrent,
        updatedSiteArea.voltage,
        updatedSiteArea.numberOfPhases,
      );
    }

    if (updatedSiteArea.smartChargingParameters?.safetyMargin) {
      updatedSiteArea.smartChargingParameters.safetyMargin = updatedSiteArea.smartChargingParameters.safetyMargin * 100;
    }

    return updatedSiteArea;
  }

  private updateSiteAreaSmartChargingInformation<T extends Partial<SiteArea>>(siteArea: T): T {
    const updatedSiteArea = Utils.cloneObject(siteArea);
    // Convert watt to amps
    if (
      updatedSiteArea.smartChargingParameters?.defaultCurrent &&
      updatedSiteArea.voltage &&
      updatedSiteArea.numberOfPhases
    ) {
      updatedSiteArea.smartChargingParameters.defaultCurrent = this.wattToAmps(
        updatedSiteArea.smartChargingParameters?.defaultCurrent,
        updatedSiteArea.voltage,
        updatedSiteArea.numberOfPhases,
      );
    }

    // Convert watt to amps
    if (
      updatedSiteArea.smartChargingParameters?.minimalCurrent &&
      updatedSiteArea.voltage &&
      updatedSiteArea.numberOfPhases
    ) {
      updatedSiteArea.smartChargingParameters.minimalCurrent = this.wattToAmps(
        updatedSiteArea.smartChargingParameters?.minimalCurrent,
        updatedSiteArea.voltage,
        updatedSiteArea.numberOfPhases,
      );
    }

    if (updatedSiteArea.smartChargingParameters?.safetyMargin) {
      updatedSiteArea.smartChargingParameters.safetyMargin = updatedSiteArea.smartChargingParameters.safetyMargin / 100;
    }
    // Force smartcharging parameter to false if undefined
    if (updatedSiteArea.smartCharging === undefined) {
      updatedSiteArea.smartCharging = false;
    }

    return updatedSiteArea;
  }

  private ampsToWatt(amps: number, voltage: number, numberOfPhases: number): number {
    if (amps === 0 || voltage === 0 || numberOfPhases === 0) {
      return 0;
    }

    return amps * voltage * numberOfPhases;
  }

  private wattToAmps(watt: number, voltage: number, numberOfPhases: number): number {
    if (watt === 0 || voltage === 0 || numberOfPhases === 0) {
      return 0;
    }

    return watt / voltage / numberOfPhases;
  }
}
